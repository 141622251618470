import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';
import styled from 'styled-components';

const Container = styled.table`
  border-spacing: 0.5rem;
  font-family: monospace;
  margin: 1rem 0;

  td {
    border: 0.125rem solid #333;
  }

  dl, dd {
    margin: 0;
  }
`;

export default class Tools extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            tools: file(relativePath: { eq: "cargo-liner/tools.jpg" }) {
              childImageSharp {
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        `}
        render={data => (
          <Container>
            <tr>
              <td>
                <Img
                  alt="Utility knife and ratchet with 10mm socket"
                  fixed={data.tools.childImageSharp.fixed}
                />
              </td>

              <td>
                <dl>
                  <dt>Socket Set</dt>
                  <dd>Kobalt</dd>

                  <dt>Utility Knife</dt>
                  <dd><a href="https://amzn.to/2MX6hL0">Stanley 10788</a></dd>
                </dl>
              </td>
            </tr>
          </Container>
        )}
      />
    )
  }
}
