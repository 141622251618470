import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';

export default class Overview extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          fragment Image on File {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          {
            label: file(relativePath: { eq: "cargo-liner/label.jpg" }) {
              ...Image
            }
            overview: file(relativePath: { eq: "cargo-liner/overview.jpg" }) {
              ...Image
            }
            rear: file(relativePath: { eq: "cargo-liner/rear.jpg" }) {
              ...Image
            }
          }
        `}
        render={data => (
          <div
            style={{
              display: `grid`,
              gridGap: `0.5rem`,
              gridTemplateColumns: `repeat(3, 1fr)`,
              margin: `1rem 0`,
            }}
          >
            <Img
              alt="An overview photo of the installed cargo liner"
              fluid={data.rear.childImageSharp.fluid}
              style={{ float: `left` }}
            />
            <Img
              alt="An overview photo of the installed cargo liner"
              fluid={data.overview.childImageSharp.fluid}
              style={{ float: `left` }}
            />
            <Img
              alt="An overview photo of the installed cargo liner"
              fluid={data.label.childImageSharp.fluid}
              style={{ float: `left` }}
            />
          </div>
        )}
      />
    )
  }
}
