module.exports = [{
      plugin: require('/Users/thunt/Work/mochapatrol/mocha-patrol/node_modules/gatsby-mdx/gatsby-browser'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":960,"linkImagesToOriginal":false,"showCaptions":true}}]},
    },{
      plugin: require('/Users/thunt/Work/mochapatrol/mocha-patrol/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-132473683-1"},
    },{
      plugin: require('/Users/thunt/Work/mochapatrol/mocha-patrol/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
