import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';
import styled from 'styled-components';

const Button = styled.button`
  background-color: ${props => props.active ? '#FF741F' : '#444'};
  border: none;
  color: ${props => props.active ? 'black' : 'white'};
  cursor: pointer;
  display: block;
  font-family: monospace;
  font-size: 1rem;
  height: 1.5rem;
  padding: 0;
  text-transform: uppercase;
`;

const Caption = styled.figcaption`
  background-color: #333;
  bottom: 1rem;
  color: white;
  font-family: monospace;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 1rem;
  position: absolute;
  text-transform: uppercase;
`;

const Container = styled.div`
  float: right;
  margin: 0 0 1rem 1rem;
  width: 600px;
`;

const Figure = styled.figure`
  margin: 0;
  position: relative;
`;

const Navigation = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(${props => props.count}, 1fr);
  margin: 0.5rem 0;
  width: 100%;
`;

export default class Slideshow extends React.Component {
  state = {
    currentSlide: 0,
  }

  show(currentSlide) {
    this.setState(state => ({ ...state, currentSlide }));
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          fragment SlideshowImage on File {
            childImageSharp {
              fixed(width: 600) {
                ...GatsbyImageSharpFixed
              }
            }
          }

          query SlideshowQuery {
            install1: file(relativePath: { eq: "cargo-liner/install1.jpg" }) {
              ...SlideshowImage
            }
            install2: file(relativePath: { eq: "cargo-liner/install2.jpg" }) {
              ...SlideshowImage
            }
            install3: file(relativePath: { eq: "cargo-liner/install3.jpg" }) {
              ...SlideshowImage
            }
            install4: file(relativePath: { eq: "cargo-liner/install4.jpg" }) {
              ...SlideshowImage
            }
            install5: file(relativePath: { eq: "cargo-liner/install5.jpg" }) {
              ...SlideshowImage
            }
          }
        `}
        render={data => {
          const { currentSlide } = this.state;
          const images = [
            {
              image: data.install1.childImageSharp.fixed,
              caption: 'Start by popping up the cover over the bolt.',
            },
            {
              image: data.install2.childImageSharp.fixed,
              caption: 'Use a 10mm socket to unscrew the bolt underneath.',
            },
            {
              image: data.install3.childImageSharp.fixed,
              caption: 'Remove the tie-down assembly.',
            },
            {
              image: data.install4.childImageSharp.fixed,
              caption: 'Cut a hole a bit smaller than the opening below it.',
            },
            {
              image: data.install5.childImageSharp.fixed,
              caption: 'Reverse the steps to reinstall the tie-down.',
            },
          ];

          return (
            <Container>
              <Figure>
                <div>
                  <Img
                    fixed={images[currentSlide].image}
                    style={{ display: `block` }}
                  />
                </div>

                <Caption>{images[currentSlide].caption}</Caption>
              </Figure>

              <Navigation count={images.length}>
                {images.map((_, index) => (
                  <Button
                    active={currentSlide === index}
                    key={index}
                    onClick={this.show.bind(this, index)}
                  >
                    Step {index + 1}
                  </Button>
                ))}
              </Navigation>
            </Container>
          );
        }}
      />
    )
  }
}
